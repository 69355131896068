import { jsx as e, jsxs as m } from "react/jsx-runtime";
import { Dropdown as R, KebabToggle as F, DropdownToggle as U, DropdownItem as h, PageHeader as A, Brand as C, PageHeaderTools as O, PageHeaderToolsGroup as D, PageHeaderToolsItem as f, Avatar as K } from "@patternfly/react-core";
import { useState as y, createContext as b, useContext as w, useCallback as I, useMemo as k } from "react";
import u from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as N } from "@patternfly/react-styles";
const v = ({
  isKebab: t = !1,
  title: n,
  dropDownItems: o,
  ...l
}) => {
  const [r, s] = y(!1);
  return /* @__PURE__ */ e(
    R,
    {
      ...l,
      isPlain: !0,
      position: "right",
      toggle: t ? /* @__PURE__ */ e(F, { onToggle: s, children: n }) : /* @__PURE__ */ e(U, { onToggle: s, children: n }),
      isOpen: r,
      dropdownItems: o
    }
  );
}, z = {
  avatar: "Avatar",
  fullName: "{{givenName}} {{familyName}}",
  manageAccount: "Manage account",
  signOut: "Sign out",
  unknownUser: "Anonymous"
}, x = b(z), M = () => w(x), L = ({
  translations: t,
  children: n
}) => /* @__PURE__ */ e(x.Provider, { value: t, children: n }), H = () => {
  const t = M(), n = I(
    (o, l) => {
      const r = t[o];
      return l ? Object.entries(l).reduce(
        (s, [p, i]) => s.replaceAll(`{{${p}}}`, i),
        r
      ) : r;
    },
    [t]
  );
  return k(() => ({ t: n }), [n]);
};
function S(t, n) {
  if (!t)
    return n("unknownUser");
  const o = t.mobile_number, l = t.email, r = t.preferred_username;
  return o || l || r || n("unknownUser");
}
const j = ({
  className: t = "",
  border: n,
  size: o = "md"
}) => /* @__PURE__ */ m(
  "svg",
  {
    className: N(
      u.avatar,
      u.modifiers[o],
      n === "light" && u.modifiers.light,
      n === "dark" && u.modifiers.dark,
      t
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ e(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ e("defs", { children: /* @__PURE__ */ e(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ e("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ e(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ e("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ e(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ e("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ m("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ e(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ e(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), T = b(void 0), _ = () => w(T), Q = ({
  keycloak: t,
  children: n
}) => {
  const [o, l] = y(t.tokenParsed), r = k(
    () => ({
      keycloak: t,
      token: o,
      updateToken: async () => {
        await t.updateToken(-1), l(t.tokenParsed);
      }
    }),
    [t, o]
  );
  return /* @__PURE__ */ e(T.Provider, { value: r, children: n });
}, V = ({
  brand: { href: t, ...n },
  avatar: o,
  features: {
    hasLogout: l = !0,
    hasManageAccount: r = !0,
    hasUsername: s = !0
  } = {},
  kebabDropdownItems: p,
  dropdownItems: i = [],
  toolbarItems: B,
  ...P
}) => {
  const { t: a } = H(), { keycloak: d } = _(), c = [];
  r && c.push(
    /* @__PURE__ */ e(
      h,
      {
        onClick: () => d?.accountManagement(),
        children: a("manageAccount")
      },
      "manageAccount"
    )
  ), l && c.push(
    /* @__PURE__ */ e(h, { onClick: () => d?.logout(), children: a("signOut") }, "signOut")
  );
  const g = d?.tokenParsed?.picture;
  return /* @__PURE__ */ e(
    A,
    {
      ...P,
      logo: /* @__PURE__ */ e(C, { ...n }),
      logoProps: { href: t },
      headerTools: /* @__PURE__ */ m(O, { children: [
        /* @__PURE__ */ m(D, { children: [
          /* @__PURE__ */ e(
            f,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ e(
                v,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...p || i,
                    c
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ e(f, { children: B }),
          /* @__PURE__ */ e(
            f,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ e(
                v,
                {
                  "data-testid": "options",
                  dropDownItems: [...i, c],
                  title: s ? S(d?.tokenParsed, a) : void 0
                }
              )
            }
          )
        ] }),
        g || o?.src ? /* @__PURE__ */ e(K, { src: g, alt: a("avatar"), ...o }) : /* @__PURE__ */ e(j, { ...o })
      ] })
    }
  );
};
export {
  V as KeycloakMasthead,
  Q as KeycloakProvider,
  L as TranslationsProvider,
  z as defaultTranslations,
  _ as useKeycloak
};
